import SingleProduct from "./Product/product";

export const Gallery = (props) => {
  return (
    <div
      id="products"
      className="text-center"
    >
      <div className="container">
        <div className="section-title">
          <h2>Products</h2>
        </div>
        <div className="row">
          <div className="products-items">
            <SingleProduct product={props.data} />
          </div>
        </div>
      </div>
    </div>
  );
};

import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import JsonData from "../data/data.json"; // Import the JSON data

export const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Find the product by id in the Gallery array
    const foundProduct = JsonData.Gallery.find((item) => item.id === id);
    setProduct(foundProduct);
  }, [id]);

  return (
    <div>
      <Navigation mainPage={false} />
      <div className="details-container mt-5">
        {product ? (
          <div>
            <h2 className="product-title">{product?.title}</h2>
            {product?.literature && (
              <p>
                <h3>Literature:</h3> {product.literature}
              </p>
            )}
            {product?.thumbnail && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "12px",
                  marginTop: "12px",
                }}
              >
                <img
                  src={product?.thumbnail}
                  alt={product?.title}
                  style={{
                    maxWidth: "100%",
                  }}
                  className="img-fluid"
                />
              </div>
            )}

            {product?.detailsArray && (
              <>
                <h3 className="mt-4">Specifications</h3>
                <div className="col-md-6 col-sm-12">
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>Physic/Chemical Parameter</th>
                        <th>Avg Values</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(product?.details).map(([key, value]) => (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>{value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {product?.perBags && (
              <div
                className="col-md-6 col-sm-12"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "12px",
                }}
              >
                <p
                  style={{
                    color: "green",
                  }}
                >
                  Available in {product.perBags} Bags
                </p>
                <p
                  style={{
                    color: "gray",
                    fontStyle: "italic",
                  }}
                >
                  Origin: {product?.origin}
                </p>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              color: "red",
            }}
          >
            <h1>No Product found</h1>
          </div>
        )}
      </div>
    </div>
  );
};

import { Link } from "react-router-dom";
import logo from "../img/logo.jpg";

export const Navigation = ({ mainPage }) => {
  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span> <span className="icon-bar"></span>{" "}
          </button>
          {mainPage ? (
            <a
              className="navbar-brand page-scroll"
              href="#page-top"
            >
              <img
                src={logo}
                alt="brand logo"
              />
            </a>
          ) : (
            <Link
              to="/"
              className="navbar-brand page-scroll"
            >
              <img
                src={logo}
                alt="brand logo"
              />
            </Link>
          )}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          {mainPage ? (
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a
                  href="#features"
                  className="page-scroll"
                >
                  Features
                </a>
              </li>
              <li>
                <a
                  href="#products"
                  className="page-scroll"
                >
                  Products
                </a>
              </li>
              <li>
                <a
                  href="#services"
                  className="page-scroll"
                >
                  Delivery
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className="page-scroll"
                >
                  About
                </a>
              </li>

              {/* <li>
              <a href="#testimonials" className="page-scroll">
                Reviews
              </a>
            </li> */}
              {/* <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
              <li>
                <a
                  href="#contact"
                  className="page-scroll"
                >
                  Contact
                </a>
              </li>
            </ul>
          ) : (
            <ul className="nav navbar-nav navbar-right">
              <li className="active">
                <a className="page-scroll">Product Details</a>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};
